.App {
  text-align: center;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.nav-pills li button{
  border: 1px solid #222FB9 !important;
  background-color: #fff !important;
  color: #282c34 !important;
}


.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: var(--bs-nav-pills-link-active-color) !important;
  background-color: #222FB9 !important;
}

.btn-info{
  background-color: #222FB9 !important;
  color: #fff !important;
border:none !important;
font-size:  14px !important;
} 


.widget{
  background-color: #222FB9 !important;
  min-height: 120px !important;

}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.card{
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




.sidebar-wrapper {
  background-clip: padding-box;
  background-color: rgb(34,47,185);
  border-right: 1px solid #e2e3e4;
  height: 100%;
  left: 0;
  overflow-y:scroll;
  position: fixed;
  top: 0;
  transition: all .2s;
  width: 300px;
  z-index: 16;
}

.sidebar-wrapper::-webkit-scrollbar {
  display: none;
}

.sidebar-wrapper .metismenu {
  background: 0 0;
  display: flex;
  flex-direction: column;
  padding: 10px;
}


.sidebar-wrapper ul {
  background: 0 0;
  list-style: none;
  margin: 0;
  padding: 0;
}


.sidebar-wrapper .metismenu li+li, .sidebar-wrapper .metismenu li:first-child {
  margin-top: 5px;
}
.sidebar-wrapper .metismenu>li {
  display: flex;
  flex-direction: column;
  position: relative;
}


.sidebar-wrapper .metismenu a {
  align-items: center;
  border-left: 4px solid hsla(0,0%,100%,0);
  border-radius: 4px;
  color: #d2d2d2;
  display: flex;
  font-size: 15px;
  justify-content: left;
  letter-spacing: .5px;
  outline-width: 0;
  overflow: hidden;
  padding: 10px 15px;
  position: relative;
  text-overflow: ellipsis;
  transition: all .2s ease-out;
}

.metismenu .accordion-collapse {
  margin: 0;
}


a {
  text-decoration: none;
  
}


.accordion-button:hover{
  background: rgba(255,255,255,0.4);
  border-radius: 30px !important;
  padding-left: 0px !important;
  /* padding-right: 0px !important; */
}

.accordion-button{
  padding-left: 0px !important;
}

.accordion-button:hover i{
  border-radius: 50%;
  background-color: white;
  padding: 15px;
  color: blue;
  /* width: 40px;
  height: 40px; */
}



.h-100 {
  height: 100vh !important;
}
.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 15px);
  padding-left: var(--bs-gutter-x, 15px);
  margin-right: auto;
  margin-left: auto;
}
* {
  outline: none;
  padding: 0;
}
*, *::before, *::after {
  box-sizing: border-box;
}


.authincation-content {
  background: #fff;
  box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
  border-radius: 5px;
}
* {
  outline: none;
  padding: 0;
}
*, *::before, *::after {
  box-sizing: border-box;
}

.auth-form {
  padding: 50px 50px;
}
* {
  outline: none;
  padding: 0;
}


.btn-primary{
  background-color: rgb(34,47,185);
  border: rgb(34,47,185);
}