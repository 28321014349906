body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    background-color: #f7f8fa;
    color: #4c5258;
    font-family: Roboto,sans-serif;
    font-size: 14px;
    letter-spacing: .5px;
    overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.metismenu .accordion-button:after {
  background-size: 14px;
  font-weight: 700;
  height: 16px;
  width: 16px;
}
.accordion-button:after {
  background-size: 1.25rem;
  content: "";
  transition: transform .2s ease-in-out,-webkit-transform .2s ease-in-out;
  background-repeat: no-repeat;
  background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E);
}
